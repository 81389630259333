html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
  line-height: 1;
}

ol, ul {
  list-style: none;
}

iframe {
  border: none;
}

button {
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  font-size: inherit;
  color: inherit;
  background: none;
  text-decoration: none;
  font-family: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
}
